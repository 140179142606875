require("dotenv").config();
const alchemyKey = process.env.REACT_APP_ALCHEMY_KEY;
const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
const web3 = createAlchemyWeb3(alchemyKey);

const contractABI = require("../contracts/bulk-transfer-abi.json");
const IERC20ABI = require("../contracts/IERC20-abi.json");
const contractAddress = "0x890eEaf23fbd981653C74EcE9dbB7841D87F76F2";
const bignumber = require("bignumber.js")

export const helloWorldContract = new web3.eth.Contract(
  contractABI,
  contractAddress
);

export const connectWallet = async () => {
  if (window.ethereum) {
    try {
      const addressArray = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      const obj = {
        status: "👆🏽 CSV形式で送信先のデータを入力してください.",
        address: addressArray[0],
      };
      return obj;
    } catch (err) {
      return {
        address: "",
        status: "😥 " + err.message,
      };
    }
  } else {
    return {
      address: "",
      status: (
        <span>
          <p>
            {" "}
            🦊{" "}
            <a target="_blank" href={`https://metamask.io/download.html`}>
              メタマスクをインストールしてください。
            </a>
          </p>
        </span>
      ),
    };
  }
};

export const getCurrentWalletConnected = async () => {
  if (window.ethereum) {
    try {
      const addressArray = await window.ethereum.request({
        method: "eth_accounts",
      });
      if (addressArray.length > 0) {
        return {
          address: addressArray[0],
          status: "👆🏽 CSV形式で送信先のデータを入力してください",
        };
      } else {
        return {
          address: "",
          status: "🦊右上のボタンからメタマスクに接続してください",
        };
      }
    } catch (err) {
      return {
        address: "",
        status: "😥 " + err.message,
      };
    }
  } else {
    return {
      address: "",
      status: (
        <span>
          <p>
            {" "}
            🦊{" "}
            <a target="_blank" href={`https://metamask.io/download.html`}>
              メタマスクをインストールしてください。
            </a>
          </p>
        </span>
      ),
    };
  }
};

export const checkAprove = async (walletAddress,amount) => {
  const tokenAddress = await helloWorldContract.methods.token().call()
  const token = new web3.eth.Contract(
    IERC20ABI,
    tokenAddress
  )
  const allownance = await token.methods.allowance(walletAddress, contractAddress).call()
  const decimals = await token.methods.decimals().call()
  const am =  bignumber(amount).times(bignumber(10).pow(decimals))
  return (am.lt(bignumber(allownance)))
}

export const aprove = async (walletAddress, setApproved, setOpen) => {
  if (!window.ethereum) {
    return {
      status:
        "💡 Connect your Metamask wallet to update the message on the blockchain.",
    };
  }
  const tokenAddress = await helloWorldContract.methods.token().call()
  console.log(tokenAddress)
  const token = new web3.eth.Contract(
    IERC20ABI,
    tokenAddress
  )
  const allowanceAmount = await token.methods.totalSupply().call()
  const transactionParameters = {
    to: tokenAddress,
    from: walletAddress,
    data: token.methods.approve(contractAddress, allowanceAmount).encodeABI(),
  };
  try {
    const txHash = await window.ethereum.request({
      method: "eth_sendTransaction",
      params: [transactionParameters],
    })
    token.events.Approval({ filter: { owner: walletAddress.toString(), spender: contractAddress.toString() } }, function (error, event) { console.log(event); })
      .on('data', function (_) {
        setApproved(true)
        setOpen(false)
      })
    return {
      status: (
        <span>
          ✅{" "}
          <a target="_blank" href={`https://polygonscan.com/tx/${txHash}`}>
            polygonscanでトランザクションを確認してください。
          </a>
        </span>
      ),
    };
  } catch (error) {
    console.log(error)
    setOpen(false)
    return {
      status: "😥 " + error.message,
    };
  }
}

export const bulkTransfer = async (addresses, amounts, walletAddress) => {
  //input error handling
  if (!window.ethereum || addresses === null || amounts === null) {
    return {
      status:
        "💡 Connect your Metamask wallet to update the message on the blockchain.",
    };
  }

  if (addresses.length === 0 || amounts.length === 0) {
    return {
      status: "❌ パラメータが不正です。",
    };
  }
  const tokenAddress = await helloWorldContract.methods.token().call()
  const token = new web3.eth.Contract(
    IERC20ABI,
    tokenAddress
  )

  const decimals = await token.methods.decimals().call()
  const reqAmounts = amounts.map(x => {
    return bignumber(x).times(bignumber(10).pow(decimals)).toString(10)
  })
  

  const transactionParameters = {
    to: contractAddress,
    from: walletAddress,
    data: helloWorldContract.methods.bulkTransfer(addresses, reqAmounts).encodeABI(),
  };

  try {
    const txHash = await window.ethereum.request({
      method: "eth_sendTransaction",
      params: [transactionParameters],
    });
    return {
      status: (
        <span>
          ✅{" "}
          <a target="_blank" href={`https://polygonscan.com/tx/${txHash}`}>
            送信しました。polygonscanでトランザクションを確認してください。
          </a>
        </span>
      ),
    };
  } catch (error) {
    return {
      status: "😥 " + error.message,
    };
  }
};

export const checkAddress = (address) => {
  return web3.utils.isAddress(address)
}
