import React from "react";
import { useEffect, useState } from "react";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import {
  helloWorldContract,
  connectWallet,
  bulkTransfer,
  getCurrentWalletConnected,
  checkAddress,
  checkAprove,
  aprove,
} from "./util/interact.js";

import TextField from '@mui/material/TextField';

const CNGTTransfer = () => {
  //state variables
  const [walletAddress, setWallet] = useState("");
  const [status, setStatus] = useState("");
  const [message, setMessage] = useState("No connection to the network."); //default message
  const [newMessage, setNewMessage] = useState("");
  const [approved, setApproved] = useState(false)
  const [open, setOpen] = useState(false)

  //called only once　0xb77A1adDDF1527B2b596Cb2545D81ce27204B123,1
  useEffect(async () => {

    const { address, status } = await getCurrentWalletConnected();

    if (address) {
      const isAproved = await checkAprove(address, 1)
      setApproved(isAproved)
    }

    setWallet(address);
    if (address !== walletAddress) {
      setStatus(status);
    }

    addWalletListener();
  });

  function addWalletListener() {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (accounts) => {
        if (accounts.length > 0) {
          setWallet(accounts[0]);
          setStatus("👆🏽 CSV形式で送信先のデータを入力してください");
        } else {
          setWallet("");
          setStatus("🦊右上のボタンからメタマスクに接続してください");
        }
      });
    } else {
      setStatus(
        <p>
          {" "}
          🦊{" "}
          <a rel="noreferrer" target="_blank" href={`https://metamask.io/download.html`}>
            メタマスクをインストールしてください。
          </a>
        </p>
      );
    }
  }

  const connectWalletPressed = async () => {
    const walletResponse = await connectWallet();

    if (walletResponse.address) {
      const isAproved = await checkAprove(walletResponse.address, 1)
      setApproved(isAproved)
    }
    setStatus(walletResponse.status);
    setWallet(walletResponse.address);
  };
  const checkInput = () => {
    const rows = newMessage.split('\n')
    const addresses = []
    const amounts = []
    for (let i = 0; i < rows.length; i++) {
      const row = rows[i];
      const cols = row.split(',')
      if (cols.length !== 2) {
        setStatus((i + 1) + '行目のデータ数が不正です');
        return
      }
      if (!checkAddress(cols[0])) {
        setStatus((i + 1) + '行目のアドレスが不正です');
        return
      }
      const amount = parseFloat(cols[1])
      if (isNaN(amount)) {
        setStatus((i + 1) + '行目の数量が不正です');
        return
      }
      addresses.push(cols[0])
      amounts.push(cols[1])
    }
    return {addresses,amounts}
  }

  const onUpdatePressed = async () => {
    // const { status } = await updateMessage(walletAddress, newMessage);
    if (!newMessage) {
      setStatus('😥 データを入力してください');
      return
    }
    const inputs = checkInput()
    const amount = inputs.amounts.reduce(function (sum, element) {
      return sum + Number(element);
    }, 0);
    const isAproved = await checkAprove(walletAddress, amount)
    if (!isAproved) {
      // await aprove(walletAddress)
      setApproved(isAproved)
      return
    }
    const { status } = await bulkTransfer(inputs.addresses, inputs.amounts, walletAddress);
    setStatus(status);
  };

  const approve = async () => {
    setOpen(true)
    await aprove(walletAddress, setApproved, setOpen).catch(()=>setOpen(false))
 }

  //the UI of our component
  return (
    <div id="container">
      <button id="walletButton" onClick={connectWalletPressed}>
        {walletAddress.length > 0 ? (
          "Connected: " +
          String(walletAddress).substring(0, 6) +
          "..." +
          String(walletAddress).substring(38)
        ) : (
          <span>ウォレットに接続</span>
        )}
      </button>

      <div>
        <TextField
          multiline
          placeholder="例)0xb77A1adDDF1527B2b596Cb2545D81ce27204B123,50
          0xb77A1adDDF1527B2b596Cb2545D81ce27204B123,50"
          rows={4}
          fullWidth
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          variant="standard"
        />
        <p id="status">{status}</p>

        {walletAddress > 0?(
          approved ?
          <button id="publish" onClick={onUpdatePressed}>
            送信
          </button> :
          <button id="publish" onClick={approve}>
            CNGTの使用を許可する
          </button>
        ) :
          <button id="publish" onClick={connectWalletPressed}>
            <span>ウォレットに接続</span>
          </button>
        }
      </div>
      <span>
        Contract address:
      </span>
      <br />
      <span>
        <a target="_blank" href={`https://polygonscan.com/address/${helloWorldContract._address}`}>
          {helloWorldContract._address}
        </a>
      </span>
      <br />
      <br />
      <span>
        created by&nbsp;
        <a target="_blank" href={`https://twitter.com/nft_syou`}>
          syou
        </a>
      </span>
      <br />
      <Backdrop
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default CNGTTransfer;
